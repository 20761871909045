
import { defineComponent, ref, onMounted, computed, watch, onUnmounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations, Modules } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import moment from "moment-timezone";

export default defineComponent({
  name: "SignIn",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    let timezones = computed(() => {
      return store.getters[Modules.MASTER + "getTimezones"];
    });

    const timezone = ref("");

    const redirectURL = ref("");

    const loginSubmitButton = ref<HTMLButtonElement | null>(null);
    const registerSubmitButton = ref<HTMLButtonElement | null>(null);

    // Login Validation Schema
    const login = Yup.object().shape({
      username: Yup.string().email().required().label("Username"),
      password: Yup.string().required().label("Password"),
    });
    // Register Validation Schema
    const register = Yup.object().shape({
      name: Yup.string().required().label("Full Name"),
      email: Yup.string().min(4).required().email().label("Email"),
      password: Yup.string()
        .min(6)
        .max(15)
        .required()
        .matches(/^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/, "Please choose a stronger password")
        .label("Password"),
      password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
      toc: Yup.bool().required("Accept Terms and conditions is required"),
    });

    //Login submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      // store.dispatch(Actions.LOGOUT);

      if (loginSubmitButton.value) {
        // eslint-disable-next-line
        loginSubmitButton.value!.disabled = true;
        // Activate indicator
        loginSubmitButton.value.setAttribute("data-kt-indicator", "on");
      }

      values.provider = store.getters.socialAuthProvider;
      values.provider_uid = store.getters.socialAuthProviderUID;
      values.provider_token = store.getters.socialAuthToken;

      // Send login request
      store
        .dispatch(Actions.SOCIAL_LINK_LOGIN, values)
        .then(() => {
          Swal.fire({
            text: "Successfully linked your account with " + values.provider.charAt(0).toUpperCase() + values.provider.slice(1),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            var payload = {
              provider: values.provider,
              provider_uid: values.provider_uid,
              provider_token: values.provider_token,
            };
            store
              .dispatch(Actions.SOCIAL_AUTH, payload)
              .then(() => {
                // Go to page after successfully login
                // ApiService.setHeader();
                redirectURL.value = route.query.redirect as string;
                if (redirectURL.value) {
                  router.push(redirectURL.value);
                } else {
                  router.push({ name: "dashboard" });
                }
              })
              .catch((response) => {
                if (response.code == "ACCOUNT_NOT_EXISTS") {
                  router.push({ name: "socialLink" });
                } else {
                  const [error] = Object.keys(store.getters.getErrors);
                  Swal.fire({
                    text: store.getters.getErrors[error],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Try again!",
                    customClass: {
                      confirmButton: "btn fw-bold btn-light-danger",
                    },
                  });
                }
              });
          });
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          //Deactivate indicator
          loginSubmitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          loginSubmitButton.value!.disabled = false;
        });
    };

    //Register submit function
    const onSubmitRegister = (values) => {
      // Clear existing errors
      // store.dispatch(Actions.LOGOUT);

      if (registerSubmitButton.value) {
        // eslint-disable-next-line
        registerSubmitButton.value!.disabled = true;
        // Activate indicator
        registerSubmitButton.value.setAttribute("data-kt-indicator", "on");
      }

      values.provider = store.getters.socialAuthProvider;
      values.provider_uid = store.getters.socialAuthProviderUID;
      values.provider_token = store.getters.socialAuthToken;

      // Send register request
      store
        .dispatch(Actions.SOCIAL_LINK_SIGNUP, values)
        .then(() => {
          Swal.fire({
            text:
              "You have successfully created and linked your new account with " +
              values.provider.charAt(0).toUpperCase() +
              values.provider.slice(1) +
              "!\n\nPlease check your email for a verification link.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            var payload = {
              provider: values.provider,
              provider_uid: values.provider_uid,
              provider_token: values.provider_token,
            };
            store
              .dispatch(Actions.SOCIAL_AUTH, payload)
              .then(() => {
                // Go to page after successfully login
                redirectURL.value = route.query.redirect as string;
                if (redirectURL.value) {
                  router.push(redirectURL.value);
                } else {
                  router.push({ name: "dashboard" });
                }
              })
              .catch((response) => {
                if (response.code == "ACCOUNT_NOT_EXISTS") {
                  router.push({ name: "socialLink" });
                } else {
                  const [error] = Object.keys(store.getters.getErrors);
                  Swal.fire({
                    text: store.getters.getErrors[error],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Try again!",
                    customClass: {
                      confirmButton: "btn fw-bold btn-light-danger",
                    },
                  });
                }
              });
          });
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          //Deactivate indicator
          registerSubmitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          registerSubmitButton.value!.disabled = false;
        });
    };

    onMounted(() => {
      defaultTimezone();
      if (!store.getters.socialAuthProvider) {
        router.push({ name: "signIn" });
      }
    });

    let defaultTimezone = () => {
      if (Object.keys(timezones.value).length !== 0) {
        timezone.value = timezones.value.filter((e) => e.code === moment.tz.guess()).length > 0 ? moment.tz.guess() : "";
      }
    };

    watch(timezones, (value) => {
      defaultTimezone();
    });

    onUnmounted(() => {
      store.commit(Mutations.SET_SOCIAL_AUTH_PROVIDER, "");
      store.commit(Mutations.SET_SOCIAL_AUTH_PROVIDER_UID, "");
      store.commit(Mutations.SET_SOCIAL_AUTH_TOKEN, "");
    });

    return {
      webURL: process.env.VUE_APP_WEB_URL,
      timezones,
      timezone,
      onSubmitLogin,
      login,
      loginSubmitButton,

      onSubmitRegister,
      register,
      registerSubmitButton,

      socialProvider: store.getters.socialAuthProvider,
    };
  },
});
